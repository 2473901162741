@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';
@import 'utils/component-spacing';

@import 'components/_slider/mixin';

/**
 * It is intended, to not use the same config pattern (js/conlumnsConfig) as for other slider-based componets,
 * because Products Carousels should have the same config as product grid for consistency.
 * If custom columns config is needed, redefine it.
 */
$products-carousel_item-per-row: deep-get(
    $view-xml,
    vars,
    MageSuite_ContentConstructor,
    columns,
    one-column
) !default;

$products-carousel_mobile-gap: 0.8rem !default;
$products-carousel_desktop-gap: 0.8rem !default;
$products-carousel_next-slide-visible: 0px !default; // it will be this value + page-gutter

.#{$ns}products-carousel {
    $root: &;

    @include component-spacing();

    @include slider(
        $columns-config: $products-carousel_item-per-row,
        $m-gap: $products-carousel_mobile-gap,
        $d-gap: $products-carousel_desktop-gap,
        $next-visible: $products-carousel_next-slide-visible,
        $root: $root
    );

    &__wrapper {
        position: relative;
        z-index: 2;
    }
}
