/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/***
 * Hide native browser's scrollbar
 */
/**
 * It is intended, to not use the same config pattern (js/conlumnsConfig) as for other slider-based componets,
 * because Products Carousels should have the same config as product grid for consistency.
 * If custom columns config is needed, redefine it.
 */
.cs-products-carousel {
  margin: 0 0 3rem;
  --gap: 0.8rem;
  --base-size: calc(100% - 0px);
  --size: var(--base-size);
  /**
     * class-based columns config relates to classnames, like '--items-in-row-4'
     * which are added to elements, depending on the CC Components configuration.
     */
  --size: calc(
            var(--base-size) /
                2 -
                1 *
                var(--gap) /
                2
        );
  /*  container padding fix  */ }
  @media (min-width: 48em) {
    .cs-products-carousel {
      margin: 0 0 4rem; } }
  .cs-products-carousel--skip-spacing {
    margin: 0; }
  @media (min-width: 81.875em) {
    .cs-products-carousel.cs-products-carousel--container-width {
      --base-size: calc(128rem - 0px); }
    .cs-products-carousel.cs-products-carousel--use-whole-screen:not(.cs-products-carousel--full-width-slider) {
      --base-size: 100%;
      max-width: 100%; }
      .cs-products-carousel.cs-products-carousel--use-whole-screen:not(.cs-products-carousel--full-width-slider) .cs-products-carousel__slides {
        scroll-snap-type: x proximity;
        padding-left: calc((100% - 128rem) / 2);
        padding-right: calc((100% - 128rem) / 2);
        scroll-padding-left: calc((100% - 128rem) / 2);
        scroll-padding-right: calc((100% - 128rem) / 2);
        scroll-margin-left: calc((100vw - 128rem) / 2);
        scroll-margin-right: calc((100vw - 128rem) / 2); } }
  @media (hover: hover) and (pointer: fine) and (min-width: 81.875em) {
    .cs-products-carousel.cs-products-carousel--container-width {
      --base-size: 128rem; } }
  @media (min-width: 48em) {
    .cs-products-carousel {
      --gap: 0.8rem; } }
  @media (min-width: 23.125em) {
    .cs-products-carousel {
      --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ); } }
  @media (min-width: 30em) {
    .cs-products-carousel {
      --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ); } }
  @media (min-width: 48em) {
    .cs-products-carousel {
      --size: calc(
                    var(--base-size) / 3 - ((
                                    2 * var(--gap)
                                ) / 3)
                ); } }
  @media (min-width: 64em) {
    .cs-products-carousel {
      --size: calc(
                    var(--base-size) / 3 - ((
                                    2 * var(--gap)
                                ) / 3)
                ); } }
  @media (min-width: 80em) {
    .cs-products-carousel {
      --size: calc(
                    var(--base-size) / 4 - ((
                                    3 * var(--gap)
                                ) / 4)
                ); } }
  @media (min-width: 90em) {
    .cs-products-carousel {
      --size: calc(
                    var(--base-size) / 4 - ((
                                    3 * var(--gap)
                                ) / 4)
                ); } }
  @media (min-width: 120em) {
    .cs-products-carousel {
      --size: calc(
                    var(--base-size) / 4 - ((
                                    3 * var(--gap)
                                ) / 4)
                ); } }
  @media (max-width: 81.8125em) {
    .cs-products-carousel.cs-products-carousel--container-width .cs-products-carousel__slides {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      scroll-padding-left: 1.5rem;
      scroll-padding-right: 1.5rem; } }
  @media (prefers-reduced-motion: no-preference) {
    .cs-products-carousel .cs-products-carousel__slides {
      scroll-behavior: smooth; } }
  .cs-products-carousel .cs-products-carousel__slides-wrapper {
    position: relative; }
  .cs-products-carousel .cs-products-carousel__slides {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex: 0 0 100%;
    gap: 0;
    flex-wrap: nowrap;
    padding: var(--gap) 0;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scrollbar-width: thin; }
    @supports (scroll-behavior: smooth) {
      .cs-products-carousel .cs-products-carousel__slides {
        scroll-snap-type: x mandatory; } }
    @media (min-width: 48em) {
      .cs-products-carousel .cs-products-carousel__slides {
        overflow: -moz-scrollbars-none;
        scrollbar-width: none; }
        .cs-products-carousel .cs-products-carousel__slides::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
          background: transparent; }
        .cs-products-carousel .cs-products-carousel__slides::-webkit-scrollbar-thumb {
          background: transparent;
          width: 0;
          height: 0; } }
  .cs-products-carousel .cs-products-carousel__slide {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    flex: 0 0 var(--size);
    margin-right: var(--gap);
    scroll-snap-align: start;
    min-width: 0; }
    .cs-products-carousel .cs-products-carousel__slide:last-of-type {
      margin-right: 0; }
  .cs-products-carousel .cs-products-carousel__image {
    display: block; }
  .cs-products-carousel__wrapper {
    position: relative;
    z-index: 2; }
