/***
 * Hide native browser's scrollbar
 */

@mixin hide-scrollbar {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        width: 0;
        height: 0;
    }
}
